import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="pt-16 pb-28 md:pt-32 md:pb-56">
      <div className="container">
        <div className="rte text-center">
          <h1 className="text-4xl lg:text-6xl">Not Found</h1>
          <p className="text-xl lg:text-2xl">
            Sorry, we can't find that page. <br className="sm:hidden" />
            <Link to="/">Click here</Link> to go back home.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
